body 
    background-color: #f8fbfc

    .hero-single
        max-width: 100vw
        height: 300px
        background-repeat: no-repeat

        &.elaphiti
            //background-image: url(images/priroda-hero.jpg)
            background-image: url(images/Elafiti-nove/naslovna2.jpg)
            background-size: cover
            background-position: 70% 90% 

        &.dubrovnik
            background-image: url(images/Dubrovnik-nove/naslovna.png)
            background-position: 65% 50% 
            background-size: cover

    .big-map
        min-width: 99vw
        max-width: 100vw
        display: none

    .small-map
        .map
            display: block

    .wrapper
        width: 100%
        padding: 0 5%

        h1 
            font-family: Signika
            text-transform: uppercase
            font-size: 25px
            background-color: #f8fbfc
            margin-top: -60px
            padding: 20px 40px
            border-radius: 10px
            color: #1f1e1e

            &.elaphiti
                width: 320px

            &.dubrovnik
                width: 320px

        .line::before
          content: "slano"
          padding-right: 80px
          font-size: 2.5px
          color: rgb(104,104,106)
          background-color: rgb(104,104,106)
        
        .flex
            display: flex
            flex-direction: column
            margin-top: 30px

            h2
                font-family: 'Oswald', sans-serif
                text-transform: uppercase
                font-size: 25px
                color: #313131

            
            .img-container
                img
                    width: 100%
                    margin-top: 50px

            .description
                width: 100%        

                p:first-of-type
                    padding-top: 30px

                p 
                    padding-top: 10px
                    color: #313131
                    font-size: 19px
                    font-family: Roboto
                    
                    a 
                        color: #1e70a3
                        text-decoration: none

            .info 
                width: 100%
                margin-top: 40px
                line-height: 2
                font-family: 'Oswald', sans-serif
                color: #313131

                p
                    font-size: 20px

                span
                    font-size: 20px
                    display: block

                .contact
                    i
                        margin-right: 5px

    .map
        max-width: 100%
        margin-top: 90px
        min-width: 100%

    .gallery
        display: flex
        flex-wrap: wrap
        margin: 0 auto
        justify-content: center
        width: 100%

        div 
            display: flex
            flex-wrap: wrap
            margin: 70px auto
            justify-content: center
            width: 100%
        
        .image-link
            overflow: hidden

            .image
                width: 180px
                height: 130px
                padding: 5px
                transition: 0.5s all

                &:hover
                    transform: scale(1.2)

    
@media screen and ( min-width: 768px)   
    body      
        .hero-single
            height: 500px

            &.dubrovnik
                background-position: none

        .big-map
            display: block

        .small-map
            .map
                display: none   

        .wrapper
            width: 90%
            margin: 0 auto

            h1 
                font-size: 40px
                margin-top: -70px

                &.elaphiti
                    width: 600px

                &.dubrovnik 
                    width: 480px
            
            .flex
                flex-direction: row
                margin-top: 70px

                .content-container
                    width: 65%

                    .description
                        //padding-left: 40px
                        width: 90%        
                        // padding-right: 80px 

                        p:first-of-type
                            padding-top: 30px     

            .img-container
                width: 35%

                img
                    width: 100%

            .info
                margin-top: 50px

                p
                    font-size: 22px

    .gallery 
        width: 80% !important
        margin: 70px auto
    
        .image-link
            .image
                width: 260px !important
                height: 200px !important
                padding: 8px !important

            .map
                max-width: 100vw
                min-width: 99vw
                margin-top: 90px

        